
.custom-file-upload {
    background-color: transparent !important;
    padding: 4px 15px;
    margin: 0;
    width: 100%;
}
.custom-file-upload:hover {
    background-color: #f2f2f2 !important;
    color: rgb(255, 49, 0) !important;
    border-color: rgb(255, 49, 0) !important;
}
.CustomButton {
    width: auto;
    /* height: auto; */
    background-color: rgb(255, 49, 0) !important;
    color: #f2f2f2 !important;
}
.CustomButton:hover {
    background-color: #f2f2f2 !important;
    color: rgb(255, 49, 0) !important;
    border-color: rgb(255, 49, 0) !important;
}